import React, { Component } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../../components/layout"
// import BackgroundImage from 'gatsby-background-image'
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"

import s from "../aboutStyles.module.scss"

// import ShowWindowDimensions from "../components/ShowWindowDimensions"

class OtherProjects extends Component {
   constructor(props) {
      super(props)

      this.state = { narrowScreen: false }
   }

   componentDidMount() {
      this.updateDimensions()
      window.addEventListener("resize", this.updateDimensions)
   }

   componentDidUpdate(prevProps, prevState) {}

   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions)
   }

   updateDimensions = () => {
      window.innerWidth < 540
         ? this.setState({ narrowScreen: true })
         : this.setState({ narrowScreen: false })
   }

   render() {
      const pageImages = this.props.data.allFile.nodes.map(
         image => image.childImageSharp.fluid
      )

      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text: "Check out some of my recent work here.",
               link: "about-me",
            },
            { text: "Or click here to get in touch with me.", link: "contact" },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <ParallaxProvider>
            <div className={`mainContainer ${s.aboutMePage}`}>
               <Layout
                  title="Other Projects"
                  superTitle="Mr BJ Jackson"
                  pageStyles={s}
                  animationStatus={animationStatus}
                  footerContent={footerContent}
               >
                  <div
                     className="articleSection row4"
                     style={{ marginTop: "100px" }}
                  >
                     <div className="left">
                        <Parallax
                           y={["-50px", "50px"]}
                           disabled={this.state.narrowScreen}
                        >
                           <Image
                              fluid={pageImages.find(
                                 image =>
                                    image.originalName === "cornishcoast.jpg"
                              )}
                           />
                        </Parallax>
                     </div>
                     <div className="right">
                        <p>
                           I'm not collecting any data so chill.{" "}
                           <span role="img" aria-label="sad face emoji">
                              😔
                           </span>
                        </p>
                     </div>
                  </div>
               </Layout>
            </div>
         </ParallaxProvider>
      )
   }
}

export default OtherProjects

export const data = graphql`
   query {
      allFile(filter: { relativeDirectory: { eq: "about-page" } }) {
         nodes {
            name
            relativeDirectory
            childImageSharp {
               fluid {
                  aspectRatio
                  base64
                  originalImg
                  originalName
                  presentationHeight
                  presentationWidth
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  tracedSVG
               }
            }
         }
      }
   }
`
